import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import SocialLinks from "../components/social_links";
import "../styles/home.css";

const BlogIndex = ({ data }: { data: any }) => {
  const posts = data.allMarkdownRemark.nodes.slice(0, 2); // Only take the first 2 posts

  return (
    <Layout>
      <header>
        <div className="font-semibold">
          <p className="mb-1">
            <span className="text-xl mb-1 inline-block">
              <span aria-label="Hi!" role="img">👋</span> Hey, <strong className="name">I'm Demóstenes</strong>
            </span>
            <br />
            <span className="text-text-accent">A passionate Engineer, specializing in web & mobile development, based in Panama.</span>
          </p>
        </div>
      </header>

      <div>
        <p className="text-md mb-4 text-text-secondary text-sm">With over 15 years of experience, I design, architect, and develop high-quality web and mobile applications with a focus on User Experience, Performance, and Accessibility.</p>
      </div>

      <ul className="flex flex-row gap-4 mb-0">
        <SocialLinks />
      </ul>

      <div className="flex flex-row gap-2 mt-5 mb-8">
        <Link to="/about" className="button-accent">About me</Link>
        <Link to="/blog" className="button">Leer mi blog (español)</Link>
      </div>

      <section className="mt-8">
        <h2 className="text-lg font-semibold mb-3">Últimos artículos</h2>
        <ul className="space-y-2">
          {posts.map((post: any) => {
            const title = post.frontmatter.title || post.fields.slug;
            return (
              <li key={post.fields.slug} className="border-b-2 pb-2 border-border text-sm">
                <article className="flex items-center">
                  <small className="text-text-secondary mr-2 text-xs">{post.fields.date}</small>
                  <h3 className="text-base font-medium text-xs">
                    <Link to={post.fields.slug} className="text-link hover:text-link-hover">
                      {title}
                    </Link>
                  </h3>
                </article>
              </li>
            );
          })}
        </ul>
      </section>
    </Layout>
  );
};

export default BlogIndex

export const Head = () => (
  <Seo
    title="Software Engineer, Web and Mobile Developer in Panama"
    description="Demóstenes García (he/him) is a Electronics and Communications Engineer that works as a Software Engineer Consultant for web/mobile. He is based in Panama City, Panama."
  />
);

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allMarkdownRemark(
      sort: { fields: [fields___date], order: DESC }
      limit: 2
    ) {
      nodes {
        excerpt
        fields {
          slug
          date(formatString: "MMM D", locale: "es")
        }
        frontmatter {
          title
          description
        }
      }
    }
  }
`;
