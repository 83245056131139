import React from 'react';
import { faRss, faX } from "@fortawesome/free-solid-svg-icons"
import {
  faGithub,
  faGitlab,
  faInstagram,
  faLinkedin,
  faGoodreads,
} from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';

const SocialLinks = () => {
  return (
    <>
     <li>
        <a
          href="https://www.x.com/demogar"
          title="Twitter @demogar"
          target="_blank"
          rel="noopener noreferrer"
          className="text-link hover:text-link-hover"
        >
          <FontAwesomeIcon icon={faX} />
          <span className="sr-only">Twitter</span>
        </a>
      </li>
      <li>
        <a
          href="https://www.instagram.com/demogar"
          title="Instagram @demogar"
          target="_blank"
          rel="noopener noreferrer"
          className="text-link hover:text-link-hover"
        >
          <FontAwesomeIcon icon={faInstagram} />
          <span className="sr-only">Instagram</span>
        </a>
      </li>
      <li>
        <a
          href="https://www.github.com/demogar"
          title="GitHub @demogar"
          target="_blank"
          rel="noopener noreferrer"
          className="text-link hover:text-link-hover"
        >
          <FontAwesomeIcon icon={faGithub} />
        </a>
      </li>
      <li>
        <a
          href="https://www.gitlab.com/demogar"
          title="GitLab @demogar"
          target="_blank"
          rel="noopener noreferrer"
          className="text-link hover:text-link-hover"
        >
          <FontAwesomeIcon icon={faGitlab} />
        </a>
      </li>
      <li>
        <a
          href="https://www.linkedin.com/in/demogar"
          title="LinkedIn - Demóstenes García G."
          target="_blank"
          rel="noopener noreferrer"
          className="text-link hover:text-link-hover"
        >
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
      </li>
      <li>
        <a
          href="https://www.goodreads.com/demogar"
          title="Goodreads - Demóstenes García G."
          target="_blank"
          rel="noopener noreferrer"
          className="text-link hover:text-link-hover"
        >
          <FontAwesomeIcon icon={faGoodreads} />
        </a>
      </li>
      <li>
        <Link
          to="/rss.xml"
          title="RSS Feed"
          target="_blank"
          rel="noopener noreferrer"
          className="text-link hover:text-link-hover"
        >
          <FontAwesomeIcon icon={faRss} />
        </Link>
      </li>
    </>
  );
};

export default SocialLinks;
